<template>
  <BaseCard
    title="datos del producto"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <ProductForm
      ref="product-form"
      v-model="product"
    />
  </BaseCard>
</template>

<script>
import ProductsApi from '@/api/products-api'
import ProductForm from '@/components/products/form/ProductForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ProductForm,
  },
  data() {
    return {
      loading: false,
      product: {},
    }
  },
  methods: {
    async handleSaveButtonClick() {
      const valid = await this.$refs['product-form'].validateForm()

      if (!valid) {
        return
      }

      this.loading = true
      try {
        const response = await ProductsApi.create(this.product)
        await this.$router.replace({ name: 'viewProduct', params: { id: response.data.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
